.eventListItem_focused {
  border: 4px solid #ff1b03 !important;
}
.event__date {
  margin: 10px 0px;
  font-size: 12px;
  line-height: 12px;
}
.event_users {
  color: #f00;
}
.eventList .field_related label {
  display: none !important;
}
.loading-event {
  display: block;
  padding: 20px;
  text-align: center;
  border: 1px solid #000;
  vertical-align: middle;
  background: #fff;
  margin-bottom: 20px;
}
.event-status {
  margin-left: -20px !important;
  padding-left: 15px !important;
}
.event-status i {
  margin: 0 !important;
}
.eventListItem img {
  max-width: 100%;
}
.eventListItem .mz-publish-context-cite__image {
  width: 190px;
  max-width: 190px;
  max-height: 150px;
}
.eventListItem .mz-publish-context-cite {
  position: relative;
  display: flex;
  width: 100%;
  flex-direction: row;
  justify-content: space-evenly;
  padding: 30px 0;
  margin: 30px 0;
  border-bottom: 1px dotted #d1d1d1;
  border-top: 1px dotted #d1d1d1;
}
.eventListItem .mz-publish-context-cite_side,
.eventListItem .mz-publish-context-cite_side-right {
  width: 35%;
  flex-direction: column;
}
.eventListItem .mz-publish-context-cite_side .mz-publish-context-cite__image,
.eventListItem .mz-publish-context-cite_side-right .mz-publish-context-cite__image {
  margin-bottom: 30px;
}
.eventListItem .mz-publish-context-cite_side {
  float: left;
  margin-right: 30px;
}
.eventListItem .mz-publish-context-cite_side-right {
  float: right;
  margin-left: 30px;
  margin-right: 30px;
}
.eventListItem .mz-publish-context-cite__text {
  display: block;
  font-family: 'Graphik LC', sans-serif;
  font-size: 18px;
  font-weight: 500;
  line-height: 140%;
  color: #000;
}
.eventListItem .mz-publish-context-cite__text_meta {
  font-family: 'Graphik LC', sans-serif;
  font-weight: normal;
  font-size: 14px;
  color: #999;
  margin-bottom: 10px;
}
.eventListItem .mz-publish-context-list {
  width: 100%;
  padding: 30px;
  margin: 50px 0;
  list-style-type: none;
  box-sizing: border-box;
  font-family: 'Graphik LC', sans-serif;
  font-size: 14px;
  line-height: 140%;
  border: 1px solid #e5e5e5;
}
.eventListItem .mz-publish-context-list__title {
  font-family: 'Graphik LC', sans-serif;
  font-weight: 500;
  font-size: 18px;
  line-height: 140%;
  color: #000;
  margin: 0;
}
.eventListItem .mz-publish-context-list li {
  padding: 20px 0;
  border-bottom: 1px dotted #e5e5e5;
}
.eventListItem .mz-publish-context-list li:last-child {
  border-bottom: none;
  padding-bottom: 0;
}
.eventListItem .mz-publish-context-list li a {
  transition: 0.2s;
  color: #000;
}
.eventListItem .mz-publish-context-list li a:hover {
  color: #ab0000;
}
