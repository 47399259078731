.webpush {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}
.webpush-content-container {
  padding: 10px;
  display: flex;
  flex-direction: column;
  justify-content: space-around;
}
.webpush-wrapper {
  display: flex;
  width: 80%;
  background-color: #fff;
  border-radius: 4px;
  margin: 10px 0;
}
.webpush img {
  border-bottom-left-radius: 4px;
  border-top-left-radius: 4px;
}
.webpush ul {
  list-style: none;
}
.webpush .header {
  border-bottom: 1px solid rgba(34,36,38,0.15);
  font-size: 1.28em;
  font-weight: bold;
  font-family: Arial;
  line-height: 1.2857em;
  margin: 0.3em 1rem;
  margin-top: 0.5em;
  padding-bottom: 0.21428571rem;
  width: 80%;
}
