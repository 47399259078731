.ActionMenuBg {
  background-color: #3d3d3d !important;
}
.ActionMenuBg .item.dark {
  background-color: #4c4c4c !important;
  color: #000 !important;
}
.ActionMenuBg .item.positive {
  background-color: #00c655 !important;
  color: #fff !important;
}
.ActionMenuBgCa {
  background-color: #4f5870 !important;
}
.ActionMenuBgCa .item.dark {
  background-color: transparent !important;
  color: #fff !important;
}
.ActionMenuBgCa .item.positive {
  background-color: #fff !important;
  color: #000 !important;
}
.ActionMenuBgCa .item.positive.active {
  color: #000 !important;
}
.ActionMenuBgCa .item.positive.active.visible {
  color: #000 !important;
}
.ActionMenuBgCa .item.positive.active.visible:hover {
  color: #000 !important;
}
.ActionMenuBgCa .item:hover {
  color: #000 !important;
}
.ActionMenuBgBy {
  background-color: #1e3e2b !important;
}
.ActionMenuBgBy .item.dark {
  background-color: transparent !important;
  color: #fff !important;
}
.ActionMenuBgBy .item.positive {
  background-color: #fff !important;
  color: #000 !important;
}
.ActionMenuBgBy .item.positive.active {
  color: #000 !important;
}
.ActionMenuBgBy .item.positive.active.visible {
  color: #000 !important;
}
.ActionMenuBgBy .item.positive.active.visible:hover {
  color: #000 !important;
}
.ActionMenuBgBy .item:hover {
  color: #000 !important;
}
.ActionMenu {
  margin-top: 0 !important;
  margin-bottom: 0 !important;
  border-radius: 0 !important;
  z-index: 801 !important;
  font-size: 1.2rem !important;
  min-height: 4rem !important;
}
.ActionMenu .item {
  padding: 0 3rem !important;
  border-radius: 0 !important;
}
.ActionMenu .item.warning {
  background-color: #fdb00b !important;
  color: #fff !important;
}
.ActionMenu .item.neutral {
  background-color: #c2c2c2 !important;
  color: #000 !important;
}
.postsEditPage .ui.fixed.sticky + .ui.container {
  margin-top: 56px !important;
}
.presentUsers {
  font-size: 13px;
  max-width: 500px;
  white-space: nowrap;
}
.presentUsers .user {
  text-overflow: ellipsis;
  overflow: hidden;
  white-space: nowrap;
}
.presentUsers .user_red {
  color: #ff000c !important;
}
