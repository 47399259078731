@import url('https://fonts.googleapis.com/css?family=Fira+Mono:400,700');
@font-face {
	font-family: "Graphik LC", sans-serif;
	src: url('./font/Graphik-Bold-Cy-Web.eot');
	src: url('./font/Graphik-Bold-Cy-Web.eot?#iefix') format("embedded-opentype"),url('./font/Graphik-Bold-Cy-Web.woff') format("woff"),url('./font/Graphik-Bold-Cy-Web.ttf') format("truetype");
	font-weight: 700;
	font-style: normal
}

@font-face {
	font-family: "Graphik LC", sans-serif;
	src: url('./font/Graphik-Regular-Cy-Web.eot');
	src: url('./font/Graphik-Regular-Cy-Web.eot?#iefix') format("embedded-opentype"),url('./font/Graphik-Regular-Cy-Web.woff') format("woff"),url('./font/Graphik-Regular-Cy-Web.ttf') format("truetype");
	font-weight: 400;
	font-style: normal;
	font-stretch: normal
}

div[id^="vk_post"] {
  border: 1px solid #ccc;
  margin-bottom: 1em;
}
div[id^="vk_post"]:after {
  content: "Пост из VK";
}
.clearfix:before,
.clearfix:after {
  content: " ";
  display: table;
}

.clearfix:after {
  clear: both;
}

.clearfix {
  *zoom: 1;
}

.mz-publish-cite {
	margin: 16px 0 24px;
	position: relative;
	display: block;
	padding: 4px 0 20px;
}
.mz-publish-cite:before {
	position: absolute;
	top: 0;
	left: 0;
	display: block;
	width: 100%;
	height: 5px;
	background: url("/assets/cite-decorator.png") repeat-x 0 0;
	content: '';
}
.mz-publish-cite:after {
	position: absolute;
	bottom: 0;
	left: 0;
	display: block;
	width: 100%;
	height: 5px;
	background: url("/assets/cite-decorator.png") repeat-x 0 0;
	content: '';
}
.mz-publish-cite__image {
	display: block;
	width: 100%;
}
.mz-publish-cite__text {
	margin: 20px 0 0;
	line-height: 1.45;
	font-size: 14px;
	font-style: normal;
	color: #999;
}

.mz-publish-cite__text p{
	margin: 20px 0 0;
	line-height: 1.45;
	font-size: 14px;
	color: #999;
}
.mz-publish-cite__text span{
	margin: 20px 0 0;
	line-height: 1.45;
	font-size: 14px;
	color: #999;
}
.mz-publish-docscite__text {
	margin: 20px 0 0;
	line-height: 1.54;
	font-family: 'Fira Mono', monospace;
	font-size: 13px;
	font-style: normal;
	color: #2b2b2b;
}
.mz-publish-docscite__text p{
	margin: 20px 0 0;
	line-height: 1.54;
	font-family: 'Fira Mono', monospace;
	font-size: 13px;
	font-style: normal;
	color: #2b2b2b;
}
.mz-publish-docscite__text span{
	margin: 20px 0 0;
	line-height: 1.54;
	font-family: 'Fira Mono', monospace;
	font-size: 13px;
	font-style: normal;
	color: #2b2b2b;
}
.mz-publish-docscite__image {
	display: block;
	width: 100%;
}
.mz-publish-docscite_side-right {
  width: 40%;
  float: right;
  margin-left: 30px;
  margin-right: 30px;
}
.mz-publish-docscite_side {
  float: left;
  margin-right: 30px;
  /*margin-left: -180px;*/
  width: 40%;
}
.mz-publish-docscite_two-col {
  padding-top: 34px;
  padding-bottom: 34px;
}
.mz-publish-cite__text a {
	color: inherit;
	border-bottom-color: #999;
}
.mz-publish-cite__text a:hover {
	color: #c70000;
	border-bottom-color: #c70000;
}

.mz-publish-cite_two-col {
  padding-top: 34px;
  padding-bottom: 34px;
}

.mz-publish-cite_two-col:before {
  display: block;
  content: '';
}

.mz-publish-cite_two-col .mz-publish-cite__image {
  float: left;
  width: 214px;
}

.mz-publish-docscite_two-col .mz-publish-docscite__image {
  float: left;
  width: 214px;
}

.mz-publish-cite_two-col .mz-publish-cite__text {
  float: left;
  margin-top: 0;
  margin-left: 26px;
  width: calc(100% - 240px);
}

.mz-publish-docscite_two-col .mz-publish-docscite__text {
  float: left;
  margin-top: 0;
  margin-left: 26px;
  width: calc(100% - 240px);
}

.mz-publish-cite_side {
  float: left;
  margin-right: 30px;
  /*margin-left: -180px;*/
  width: 40%;
}

.mz-publish-cite_side-right {
  width: 40%;
  float: right;
  margin-left: 30px;
  margin-right: 30px;
}

.mz-publish-photo {
  /*position: relative;
  left: -20px;
  width: calc(100% + 40px);*/
	margin: 30px 0 20px;
}

.mz-publish-photo_original {
	margin-left: 0px;
	margin-right: 0px;
	position: relative;
	width: auto;
	text-align: center;
}


.mz-publish-photo__image {
    display: block;
    width: 100%;
}

.mz-publish-photo__comment {
	display: block;
	margin: 8px 0 0;
	padding: 0 20px;
	line-height: 1.3;
	font-size: 12px;
	color: #999;
	margin: 18px 0 0;
	padding: 0;
	font-size: 15px;
}

.mz-publish-photo_full-w .mz-publish-photo__image{
	margin-left: -10px;
	width: calc(100% + 20px);
}

.mz-publish-photo_original .mz-publish-photo__image {
	margin-left: auto;
	margin-right: auto;
	left: 0;
	width: auto;
	max-width: 100%;
}

.mz-publish__text__highlight-lead {
	font-family: "Graphik LC", sans-serif;
	line-height: 1.45;
	-webkit-font-smoothing: antialiased;
}