.PostTitle {
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
}
.PostTitle__index {
  color: #999;
  flex: 0 0 2em;
}
.PostTitle .format {
  color: #999;
  -webkit-flex-basis: 5em;
  -ms-flex-preferred-size: 5em;
  flex-basis: 5em;
  -webkit-flex-shrink: 0;
  -ms-flex-negative: 0;
  flex-shrink: 0;
}
.PostTitle .title {
  -webkit-box-flex: 1;
  -webkit-flex-grow: 1;
  -ms-flex-positive: 1;
  flex-grow: 1;
}
.field_related {
  width: 100%;
}
.relationOption {
  margin-left: 1rem;
}
.relatedField {
  margin-bottom: 20px;
}
.relatedField .PostSuggest {
  flex-grow: 1;
}
.relatedField .menu {
  display: block !important;
}
.relatedPosts.is-loading {
  opacity: 0.6;
}
.relatedPosts .relatedPostsItem {
  cursor: move;
}
.relatedPosts .relatedPostsItem:hover .unlinkPost {
  visibility: visible;
}
.relatedPosts .relatedPostsItem .unlinkPost {
  cursor: pointer;
  visibility: hidden;
  margin-left: 1em !important;
}
.relatedPosts .relatedPostsItem .unlinkPost:hover {
  color: #f00 !important;
}
.relatedPosts .relatedPlaceholder {
  position: relative;
}
.relatedPosts .relatedContainer {
  max-height: 20em;
  overflow: auto;
}
.PostSuggest .remove.icon {
  cursor: pointer;
}
.PostSuggest .message {
  display: none;
}
.Select.is-loading .Select-menu-outer {
  display: none;
}
.related-select {
  position: relative;
  z-index: 20;
}
