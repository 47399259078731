.archive-source__container {
  margin-top: 30px;
  border: solid 1px #d4d4d5;
  border-radius: 4px;
}
.archive-source__drop-button {
  float: right;
  font-family: Arial;
  font-size: 12px;
  font-weight: bold;
  color: #6a6a6b;
  border: none;
  outline: none;
  background-color: #f3f4f5;
}
.archive-source__source-container {
  background-color: #fff;
  padding: 15px;
}
.archive-source__source-card {
  box-sizing: border-box;
  padding-bottom: 21px;
  margin-bottom: 21px;
  white-space: nowrap;
  overflow: hidden;
  border-bottom: 1px solid #d4d4d5;
}
.archive-source__source-card:last-of-type {
  border-bottom: none;
  margin-bottom: 0;
  padding-bottom: 0;
}
.archive-source__source-bottom {
  padding: 0;
  margin-top: 12px;
}
.archive-source__date {
  font-family: Arial;
  font-size: 12px;
  color: #999;
}
.archive-source__source-link {
  font-family: Arial;
  font-size: 14px;
  color: #151515;
  cursor: pointer;
  text-overflow: ellipsis;
}
.archive-source__link {
  font-family: Arial;
  text-transform: uppercase;
  font-size: 11px;
  color: #00c655;
  cursor: pointer;
  margin-right: 20px;
}
.archive-source__link_zip {
  margin-right: 0;
  float: right;
}
.archive-source__link:hover {
  color: #00c655;
}
