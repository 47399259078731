.revisionsMenu {
  width: 400px !important;
  font-size: 80% !important;
  height: 350px;
  overflow: hidden;
  overflow-y: scroll;
}
.revisionsMenu .publishedRevision {
  color: #f00 !important;
}
.field {
  position: relative;
}
.fieldCounter {
  font-weight: normal !important;
}
.fieldCounter.overLimit {
  color: #f00 !important;
}
textarea + .fieldCounter {
  position: absolute;
  top: 2rem;
  right: 0.4rem;
}
.metaInfo .metaUrls {
  word-wrap: break-word;
  word-break: break-all;
}
.metaInfo .metaEditor {
  word-wrap: break-word;
  word-break: break-word;
}
.saveStatus {
  font-size: 80%;
  padding: 0 2em !important;
  color: #999 !important;
}
.saveStatus.changed {
  color: #fff !important;
}
#ckeditor_toolbar {
  margin-bottom: -44px;
  visibility: hidden;
  height: 44px;
}
#ckeditor_toolbar.visible {
  margin-bottom: 0;
  visibility: visible;
}
