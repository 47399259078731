body.dragging,
body.dragging * {
  cursor: move !important;
}
.dragged {
  position: absolute;
  top: 0;
  opacity: 0.7;
  z-index: 2000;
}
.dragPlaceholder {
  position: relative;
}
