.webpush__text-wrapper {
  position: relative;
}
.webpush__text-counter {
  position: absolute;
  right: 0;
  top: 0;
  background: #009c95;
  color: #fff;
  line-height: 1;
  font-size: 13px;
  height: 20px;
  display: block;
  z-index: 10;
  padding: 3px 10px;
}
.web-push__form {
  width: 850px;
  max-width: 100%;
}
