.mention-search__container {
  margin-top: 35px;
  width: 100%;
}
.mention-search__field {
  margin: 10px;
  margin-left: 0;
  width: 100%;
}
.mention-search__field label {
  margin-right: 10px;
}
.mention-search__field input {
  width: 300px;
  height: 30px;
}
.mention-search__field button {
  height: 30px;
}
.mention-search__result {
  margin: 35px;
  margin-left: 0px;
  display: flex;
}
.mention-search__result ul {
  list-style-type: decimal;
}
