.feature-btn_post {
  width: 100%;
  margin: 10px 0px;
}
.btn_autogenerateurl {
  margin: 10px 0px 0px 0px !important;
}
.input_error {
  background-color: #ffe8e6;
  color: #db2828;
  box-shadow: 0px 0px 0px 1px #db2;
  display: block;
  padding: 5px 10px !important;
}
.transform-post.button_disable {
  width: 240px;
  opacity: 0.5;
  cursor: default;
}
.transform-post.button_disable:hover {
  background-color: #e0e1e2;
  color: rgba(0,0,0,0.6);
}
.post-upload-images__container {
  margin: 0 0 14px 0;
}
.form_hidden {
  visibility: hidden;
}
.ui.form .field .body_label {
  display: inline-block;
}
.slate_toggle {
  float: right;
}
.illustration-type__select {
  width: 100%;
}
.ql-container.ql-snow {
  border: none;
}
