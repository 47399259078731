.featurePosts .PostTitle {
  font-size: 1em;
  line-height: 1.2em;
  padding: 0px 10px;
}
.featurePosts .feature {
  padding: 10px 0px;
  margin: 10px 0px;
  background: #fff;
}
.featurePosts .feature_dragging {
  opacity: 0.5;
}
.featurePosts .feature_active {
  position: relative;
}
.featurePosts .feature_active::after {
  content: '';
  position: absolute;
  top: -5px;
  right: 0;
  left: 0;
  border-bottom: 1px solid;
}
.relatedPostsItem_feature {
  background: rgba(34,36,38,0.1);
  color: #000;
  padding: 10px 30px 10px 10px;
  margin: 0px 0px 10px 0px;
  position: relative;
}
.relatedPostsItem_feature .title {
  margin-right: 40px;
}
.feature-input {
  width: 100%;
  margin: 0px 0px 20px 0;
}
.addPostToFeature {
  cursor: pointer;
  position: absolute;
  right: 5px;
  top: 10px;
}
.features-settings-form {
  font-size: 14px;
  font: 'Helvetica Neue';
}
.features-switcher-field {
  display: flex;
  flex-flow: row nowrap;
  justify-content: space-between;
  margin-bottom: 30px !important;
}
.features-switcher-label {
  width: 300px;
}
.features-switcher-description {
  font-size: 12px;
  color: #5d5d5d;
}
.info-form {
  background: #fff;
  border: 1px solid #d4d4d5;
  box-sizing: border-box;
  padding: 15px;
}
.info-button.ui.primary.button {
  width: 100%;
}
.features-field {
  display: flex;
  justify-content: space-between;
  align-items: center;
}
.features-field input {
  max-width: 85%;
}
