.notes-list {
  margin-top: 50px;
}
.notes-list__note-container {
  margin-top: 35px;
}
.notes-list__search {
  display: inline-block;
  float: right;
  margin-right: 10px;
  width: 355px;
}
.notes-list__item {
  box-sizing: border-box;
  background-color: #fef8d4;
}
.notes-list .notes-list__title {
  display: inline-block;
  margin-left: 10px;
}
.note {
  box-sizing: border-box;
  padding: 30px 27px 16px 30px;
  margin: 10px 10px;
  width: 355px;
  background-color: #fef8d4;
}
.note__icon svg {
  fill: #9a9a9a;
}
.note__icon_active svg {
  fill: #fff;
}
.note__text {
  opacity: 0.7;
  font-size: 14px;
  line-height: 1.71;
  color: #151515;
  margin-bottom: 30px;
  overflow-wrap: break-word;
}
.note__text p {
  line-height: 1.71;
}
.note__line {
  border-bottom: 1px solid #dbd5b1;
}
.note__bottom {
  display: flex;
  width: 100%;
  justify-content: space-between;
  margin-top: 10px;
}
.note__date {
  float: left;
  margin: 0;
  opacity: 0.3;
  font-size: 14px;
  line-height: 1.71;
  color: #151515;
}
.note__link {
  color: #4a90e2;
  margin: 0;
  opacity: 0.7;
}
