.test__textarea_short {
  max-height: 90px;
}
.test__textarea_full {
  max-height: 120px;
}
.test .answers-list {
  background: rgba(204,204,204,0.44);
}
.test .results {
  background: #f7efef;
}
.test_checkbox {
  display: block !important;
  margin: 10px 0px !important;
}
.test .ui.form textarea {
  min-height: auto !important;
}
.test .question__remove,
.test .question__save,
.test .question__edit {
  margin-bottom: 20px;
}
