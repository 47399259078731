.preview {
  display: flex;
  flex-direction: column;
  width: 100%;
}
.preview__size-icon {
  margin: 0 !important;
}
.preview__frame-container {
  position: relative;
  display: flex;
  flex-direction: row;
  justify-content: center;
}
.preview__frame-content {
  position: absolute;
  display: flex;
  height: 1000px;
  transform: scale(0.75);
  transform-origin: 50% 0;
}
.preview__hidden {
  visibility: hidden;
}
.preview__social-container {
  display: block;
  width: 1200px;
  height: 630px;
  background-position: center;
  background-size: contain;
  background-repeat: no-repeat;
  transform: scale(0.5);
  transform-origin: 50% 0;
}
