.MainMenuBg {
  background-color: #222 !important;
}
.MainMenuBg .item {
  color: #9a9a9a !important;
}
.MainMenuBg .item.green {
  color: #00c655 !important;
}
.MainMenuBg .button {
  background-color: #3d3d3d !important;
  color: #9a9a9a !important;
}
.MainMenuBgCa {
  background-color: #4f5870 !important;
}
.MainMenuBgCa .item {
  color: #fff !important;
}
.MainMenuBgCa .item.white {
  background-color: transparent !important;
  color: #fff !important;
  border-color: #fff !important;
}
.MainMenuBgCa .item.active {
  font-weight: 900 !important;
  text-decoration: underline;
}
.MainMenuBgCa .button {
  background-color: #fff !important;
  color: #3d3d3d !important;
}
.MainMenuBgBy {
  background-color: #1e3e2b !important;
}
.MainMenuBgBy .item {
  color: #fff !important;
}
.MainMenuBgBy .item.white {
  background-color: transparent !important;
  color: #fff !important;
  border-color: #fff !important;
}
.MainMenuBgBy .item.active {
  font-weight: 900 !important;
  text-decoration: underline;
}
.MainMenuBgBy .button {
  background-color: #fff !important;
  color: #3d3d3d !important;
}
.MainMenu {
  min-height: 3.4rem !important;
  border-radius: 0 !important;
  padding: 0.7rem !important;
  font-size: 1rem !important;
  margin: 0 !important;
  border-bottom: 1px solid #363636 !important;
}
.MainMenu .item.header {
  font-weight: 900;
  font-size: 2rem;
  color: #fff !important;
  padding: 0 !important;
  border-right: 2px solid #3d3d3d;
  padding: 0 1.5rem !important;
}
.MainMenu .item.header:hover {
  background-color: transparent !important;
}
.MainMenu .item.green {
  padding-right: 0.5em !important;
  background-color: transparent !important;
}
.MainMenu .item.active {
  color: #fff !important;
}
.MainMenu .item.partner {
  background-image: url("256c1d9a7a7fe8441555afd68aa483a4.svg") !important;
  background-repeat: no-repeat !important;
  background-size: 55% 100% !important;
  background-position: center center !important;
}
.MainMenu .item .icon.hashtag:before {
  content: "\f292";
}
.MainMenu .item.partner .icon:before {
  content: '';
}
.MainMenu .right.menu .item {
  padding-right: 0 !important;
}
