.authors__header {
  height: 25px;
}
.authors__left.authors__left {
  float: left;
  max-width: calc(100% - 100px);
  line-height: 30px;
}
.authors__left.authors__left input[type="text"] {
  margin-bottom: 10px !important;
}
.authors__right {
  float: right;
}
.authors__list {
  padding-left: 0;
  overflow: hidden;
}
.autoform-add-item {
  margin-top: 20px !important;
}
.autoform-array-item {
  overflow: hidden;
}
.autoform-array-item.ui.container .authors__right {
  float: none !important;
}
