.LoginForm {
  margin-top: 15em;
}
.LoginForm .field {
  line-height: 3;
}
.button_auth {
  background: #888 !important;
  color: #000 !important;
}
