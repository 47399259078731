.react_modal__overlay {
  width: 100%;
  height: 100%;
  position: fixed;
  top: 0;
  left: 0;
  display: none;
  align-items: center;
  align-content: center;
  justify-content: center;
  overflow: auto;
  z-index: 802;
  background-color: rgba(0,0,0,0.8);
}
.react_modal__overlay_opened {
  display: flex;
}
.react_modal__container {
  background-color: #fff;
  max-width: 95%;
  z-index: 803;
  max-height: 100vh;
  overflow: scroll;
}
.react_modal__header {
  font-size: 1.42857143rem;
  line-height: 1.2857em;
  font-weight: bold;
  border-bottom: 1px solid rgba(34,36,38,0.149);
  padding: 1.25rem 1.5rem;
  margin: 0em;
}
.react_modal__content {
  padding: 1.51rem;
}
